import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage-angular";
import { SavedArticles } from "../models";
import { ApiService } from "./api.service";
import { App, AppInfo } from "@capacitor/app";

@Injectable({
    providedIn: "root"
})
export class StorageService {
    private _storage: Storage | null = null;
    version = "X.X";
    appData: AppInfo = null;
    savedArticles: SavedArticles | any = {};
    categories: any = {};
    devMails = ["enkodo.nl", "apple.com", "growindigital.nl", "mijnserie.nl"];

    constructor(public nativeStorage: Storage, private apiService: ApiService, private storage: Storage) {
        this.init();
        this.savedArticles = this.setStorageObject("savedArticles", {});
        this.categories = this.setStorageObject("categories", {});
        this.getDeviceInfo();
        this.fetchCategories();
    }

    async init() {
        const storage = await this.storage.create();
        this._storage = storage;
    }

    async getDeviceInfo() {
        this.appData = await App.getInfo();
        this.version =
            this.appData.version +
            (this.appData.build ? " (" + this.appData.build + ")" : "");
        if (this.version === "") {
            this.version = "dev-web-build";
        }
    }

    // Storage functions CRUD
    setStorageObject(key, value) {
        this.setStorageObjectLocal(key, value);
        this.nativeStorage.set(key, value);

        return value;
    }

    getStorageObject(key, defaultValue: any = false) {
        if (localStorage.getItem(key) !== undefined && localStorage.getItem(key) !== null) {
            return JSON.parse(localStorage.getItem(key));
        }
        return defaultValue ? defaultValue : false;
    }

    setStorageObjectLocal(key, value) {
        localStorage.setItem(key, JSON.stringify(value));

        return value;
    }

    getStorageObjectLocal(key, defaultValue: any = false) {
        if (localStorage.getItem(key) !== undefined) {
            return JSON.parse(localStorage.getItem(key));
        }

        return defaultValue ? defaultValue : false;
    }

    removeStorage(key) {
        localStorage.removeItem(key);
        this.nativeStorage.remove(key);
    }

    clearAllStorage() {
        localStorage.clear();
        this.nativeStorage.clear();
    }

    flushCache() {
    }

    fetchCategories() {
        this.apiService.get("categories").then((data) => {
            let categories = {};
            for (let category of data) {
                categories[category.id] = category;
            }

            this.setStorageObject("categories", categories);
            this.categories = categories;
        });
    }
}
