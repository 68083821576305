import {NgModule} from "@angular/core";
import {PreloadAllModules, RouterModule, Routes} from "@angular/router";
import {NgAuthService} from "./services/ng-auth.service";


const routes: Routes = [
    {
        path: "",
        loadChildren: () =>
            import("./tabs/tabs.module").then((m) => m.TabsPageModule),
        canActivate: [NgAuthService]
    },
    {
        path: "onboarding",
        loadChildren: () =>
            import("./onboarding/onboarding.module").then(
                (m) => m.OnboardingPageModule
            ),
    },
    {
        path: "detail/:id",
        loadChildren: () =>
            import("./detail/detail.module").then((m) => m.DetailPageModule),
    },
    {
      path: 'verify',
      loadChildren: () => import('./verify/verify.module').then( m => m.VerifyPageModule)
    },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules}),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
