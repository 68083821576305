import { Component, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { Platform } from "@ionic/angular";
import { SplashScreen } from "@capacitor/splash-screen";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";
import { NgAuthService } from "./services/ng-auth.service";

import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  constructor(
    private router: Router,
    private platform: Platform,
    private statusBar: StatusBar,
    private ngAuthService: NgAuthService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      SplashScreen.hide();

      this.ngAuthService.afAuth.authState.subscribe((user) => {
        if (user && user.emailVerified) {
          this.router.navigate(["/tabs"]);
        } else {
          this.router.navigate(["/onboarding"]);
        }
      });
    });
  }
}
