import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Capacitor, CapacitorHttp } from "@capacitor/core";
@Injectable({
  providedIn: "root",
})
export class ApiService {
  apiUrl = "https://doc-doc.app//wp-json/wp/v2/";
  constructor(private http: HttpClient) {}

  get(endpoint): any {
    if (Capacitor.platform === "web") {
      return this.http.get(this.apiUrl + endpoint).toPromise();
    } else {
      return CapacitorHttp.request({
        method: "GET",
        url: this.apiUrl + endpoint,
      }).then((res) => res.data);
    }
  }

  post(endpoint, payload) {
    if (Capacitor.platform === "web") {
      return this.http.post(this.apiUrl + endpoint, payload).toPromise();
    } else {
      return CapacitorHttp.request({
        method: "POST",
        url: this.apiUrl + endpoint,
        data: payload,
      }).then((res) => res.data);
    }
  }

  put(endpoint, data) {
    return this.http.put(this.apiUrl + endpoint, data).toPromise();
  }

  delete(endpoint) {
    return this.http.delete(this.apiUrl + endpoint).toPromise();
  }
}
