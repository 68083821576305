import { Injectable, NgZone } from "@angular/core";
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
    AngularFirestore,
    AngularFirestoreDocument,
} from "@angular/fire/compat/firestore";
import { CanActivate, Router } from "@angular/router";
import { AnalyticsService } from "./analytics.service";
import 'firebase/auth';
// import FirebaseError = firebase.FirebaseError;
// import ActionCodeSettings = firebase.auth.ActionCodeSettings;
import {AlertController} from "@ionic/angular";
import {StorageService} from "./storage.service";

export interface User {
    uid: string;
    email: string;
    displayName: string;
    photoURL: string;
    emailVerified: boolean;
}

@Injectable({
    providedIn: "root",
})
export class NgAuthService implements CanActivate {
    public user: any;

    constructor(
        public afs: AngularFirestore,
        public afAuth: AngularFireAuth,
        public router: Router,
        public ngZone: NgZone,
        private analytics: AnalyticsService,
        private alertCtrl: AlertController,
        private storage: StorageService
    ) {
        this.afAuth.authState.subscribe((user) => {
            if (user) {
                this.analytics.setUser(user.uid);
                this.user = user;
            }
        });
    }

    canActivate(): boolean {
        if (!this.user !== null && this.user !== undefined) {
            return true;
        }
        console.log("onboarding");
        this.router.navigate(["onboarding"]);
        return false;
    }

    async SignIn(email, password) {
        return this.afAuth
            .signInWithEmailAndPassword(email, password)
            .then((result) => {
                console.log('SignIn success');
                this.ngZone.run(() => {
                    this.router.navigate(["dashboard"]);
                });
                this.SetUserData(result.user);
            })
            .catch((error) => {
                console.log('SignIn Error');
                window.alert(error.message);
            });
    }

    async SignUp(email) {
        // console.log('dit is het e-mailadres', email);
        const password = Math.random().toString(36).slice(-8);
        // console.log('dit wordt het wachtwoord', password);
        return this.afAuth
            .createUserWithEmailAndPassword(email, password)
            .then(async (result) => {
                await this.SendVerificationMail();
                await this.SetUserData(result.user);
            })
            .catch(async (err) => {
                if (err.code === "auth/email-already-in-use") {
                    console.log('forgot passhword');
                    // this.SignInWithEmailLink(email);
                    const toast = await this.alertCtrl.create({
                        header: 'E-mailadres bestaat al',
                        message: 'Wil je inloggen met je e-mailadres en wachtwoord?',
                        buttons: [
                            {
                                text: 'Inloggen',
                                handler: () => {
                                    this.router.navigate(['login']);
                                }
                            },
                            {
                                text: 'Annuleer',
                                role: 'cancel',
                            }
                        ]
                    });
                    toast.present();
                } else {
                    console.log("Firebase Error", err);
                }
            });
    }

    // XX SEB: WiP
    async SignInWithEmailLink(email) {
        console.log("try sigin with link");
        const actionCodeSettings = {
            url: window.location.href,
            handleCodeInApp: true,
        };
        this.afAuth
            .sendSignInLinkToEmail(email, actionCodeSettings)
            .then(() => {
                this.afAuth.currentUser.then((user) => {
                    console.log("user?", user);
                    this.router.navigate(["verify"]);
                });
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    SendVerificationMail() {
        return this.afAuth.currentUser
            .then((u) => u.sendEmailVerification())
            .then(() => {
                this.router.navigate(["verify"]);
            });
    }

    ForgotPassword(passwordResetEmail) {
        return this.afAuth
            .sendPasswordResetEmail(passwordResetEmail)
            .then(() => {
                window.alert("Password reset email sent, check your inbox.");
            })
            .catch((error) => {
                window.alert(error);
            });
    }

    async isLoggedIn(): Promise<boolean> {
        const user = await this.afAuth.currentUser;
        return user !== null && user.emailVerified !== false;
    }

    AuthLogin(provider) {
        return this.afAuth
            .signInWithEmailLink(provider)
            .then((result) => {
                this.ngZone.run(() => {
                    this.router.navigate(["dashboard"]);
                });
                this.SetUserData(result.user);
            })
            .catch((error) => {
                window.alert(error);
            });
    }

    SetUserData(user) {
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(
            `users/${user.uid}`
        );
        const userState: User = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            emailVerified: user.emailVerified,
        };
        return userRef.set(userState, {
            merge: true,
        });
    }

    SignOut() {
        return this.afAuth.signOut().then(() => {
            localStorage.removeItem("user");
            this.router.navigate(["sign-in"]);
        });
    }
}
