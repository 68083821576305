import {Injectable} from '@angular/core';
import {FirebaseAnalytics} from "@capacitor-community/firebase-analytics";

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {

    constructor() {
    }

    setUser(userId) {
        FirebaseAnalytics.setUserId({
            userId,
        });
    }

    setScreenName(screenName, nameOveride = '') {
      FirebaseAnalytics.setScreenName({
        screenName,
        nameOverride: nameOveride !== '' ? nameOveride : screenName,
      });
    }
}